/**
 * Checks value is isTruthy (with type guard for Array.filter(isTruthy))
 *
 * @param value the value to check.
 * @return is a Boolean
 */

export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value
}
