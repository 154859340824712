import Decimal from '@st/decimal'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import useCryptoFormatter from '@st/i18n/composables/useCryptoFormatter'
import useNumberFormatter from '@st/i18n/composables/useNumberFormatter'
import useCurrencyFormatter from '@st/i18n/composables/useCurrencyFormatter'
import { isTruthy } from '@st/utils'
import type { ExtendedDepositBonus } from '../types'
import { useBonusesStore } from '../stores/useBonusesStore'
import { useDepositBonusesStore } from '../stores/useDepositBonusesStore'
import { useBonusRolling } from './useBonusRolling'

/**
 * после рефакторинга и переезда на bonus/find
 * у активных бонусов за депозит
 * нужно будет вынести из этого хука поля
 * которые относятся к этим состояниям (Rolling, canCalm)
 */
export function useDepositBonus(
  bonus: Ref<ExtendedDepositBonus | null | undefined>,
) {
  const { t } = useI18n()
  const currenciesStore = useCurrenciesStore()
  const { getCurrencyById } = currenciesStore
  const { appCurrency } = storeToRefs(currenciesStore)
  const { format: formatCrypto } = useCryptoFormatter()
  const { format: formatCurrency } = useCurrencyFormatter({
    currency: appCurrency.value.code,
  })

  const { format: formatMultiplier } = useNumberFormatter()
  const { format: formatCryptoWithCurrencyCode } = useCryptoFormatter({
    style: 'currency',
  })

  const defaultDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.bonusSettings,
  )
  const freebetDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.freebet,
  )
  const freespinDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.freespinProgram,
  )
  const reloadDepositBonusSettings = computed(
    () => bonus.value?.bonusPrograms.reloadProgram,
  )

  const { format: formatFreespinFiat } = useCurrencyFormatter({
    currency: computed(() => {
      if (!freespinDepositBonusSettings.value?.freespinCurrencyId) return 'usd'

      return (
        getCurrencyById(freespinDepositBonusSettings.value.freespinCurrencyId)
          .code ?? 'usd'
      )
    }),
  })

  function getBonusCurrencyCode(id: number | undefined) {
    if (!id) return ''
    return getCurrencyById(id)?.code
  }
  function getBonusCurrencyIcon(id: number | undefined) {
    if (!id) return ''
    return getCurrencyById(id)?.icon
  }

  const bonusesCurrencies = computed(() => ({
    default: {
      code: getBonusCurrencyCode(defaultDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(defaultDepositBonusSettings.value?.currencyId),
    },
    freebet: {
      code: getBonusCurrencyCode(freebetDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(freebetDepositBonusSettings.value?.currencyId),
    },
    freespin: {
      code: null,
      icon: null,
    },
    reload: {
      code: getBonusCurrencyCode(reloadDepositBonusSettings.value?.currencyId),
      icon: getBonusCurrencyIcon(reloadDepositBonusSettings.value?.currencyId),
    },
  }))

  const bonusMultiplier = computed(() => {
    if (!defaultDepositBonusSettings.value) return null
    const amount = `+${formatMultiplier(
      new Decimal(defaultDepositBonusSettings.value.depositMultiplier ?? 0)
        .mul(100)
        .toString(),
    )}%`

    return {
      icon: null,
      amount,
      amountWithCurrency: amount,
      text: '',
    }
  })
  const freespinNumber = computed(() => {
    if (!freespinDepositBonusSettings.value) return null
    const amount = `${freespinDepositBonusSettings.value.freespinNumber} FS`
    return {
      icon: null,
      amount,
      amountWithCurrency: amount,
      text: '',
    }
  })
  const freebetAmount = computed(() => {
    if (!freebetDepositBonusSettings.value) return null

    return {
      icon: bonusesCurrencies.value.freebet.icon,
      amount: formatCrypto(freebetDepositBonusSettings.value.amount),
      amountWithCurrency: formatCryptoWithCurrencyCode(
        freebetDepositBonusSettings.value.amount,
        bonusesCurrencies.value.freebet.code,
      ),
      text: t('bonuses.freebet'),
    }
  })
  const reloadAmount = computed(() => {
    if (!reloadDepositBonusSettings.value) return null

    return {
      icon: bonusesCurrencies.value.reload.icon,
      amount: formatCrypto(reloadDepositBonusSettings.value.amount),
      amountWithCurrency: formatCryptoWithCurrencyCode(
        reloadDepositBonusSettings.value.amount,
        bonusesCurrencies.value.reload.code,
      ),
      text: t('bonuses.reload'),
    }
  })

  const filteredMultiBonuses = computed(() =>
    [
      bonusMultiplier.value,
      freespinNumber.value,
      freebetAmount.value,
      reloadAmount.value,
    ].filter(isTruthy),
  )
  const isMultiBonus = computed(() => filteredMultiBonuses.value.length > 1)
  const isStepBonus = computed(() => !!bonus.value?.steps?.length)
  const bonusesOutput = computed(() => ({
    main: filteredMultiBonuses.value[0],
    others: isMultiBonus.value ? filteredMultiBonuses.value.slice(1) : [],
  }))

  const titleType = computed(() => {
    if (isMultiBonus.value) return t('bonuses.bonus')

    if (freebetDepositBonusSettings.value) return t('bonuses.freebet')
    if (freespinDepositBonusSettings.value) return t('bonuses.freespins')
    if (reloadDepositBonusSettings.value) return t('bonuses.reload')

    return t('bonuses.bonus')
  })
  const subTitle = computed(() => {
    if (isMultiBonus.value) return null

    if (freebetDepositBonusSettings.value) return t('bonuses.freebetSubtitle')
    if (freespinDepositBonusSettings.value) return t('bonuses.freespinSubtitle')
    if (reloadDepositBonusSettings.value)
      return t(
        'bonuses.reloadSubtitle',
        reloadDepositBonusSettings.value.bonusRepeatIntervalInHours,
      )

    return t('bonuses.defaultSubtitle')
  })
  const titleByLevel = computed(() => {
    const level = bonus.value?.level
    if (!level) return t('bonuses.depositNTitle', { type: titleType.value })

    switch (level) {
      case 1:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.first'),
        })
      case 2:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.second'),
        })
      case 3:
        return t('bonuses.depositNTitle', {
          type: titleType.value,
          level: t('bonuses.levels.third'),
        })
      default:
        return t('bonuses.depositNTitle', { type: titleType.value }, level)
    }
  })

  const maxFreespinBonusFiatAmount = computed(() =>
    freespinDepositBonusSettings.value?.maxRewardAmount
      ? formatFreespinFiat(freespinDepositBonusSettings.value.maxRewardAmount)
      : 0,
  )
  const freespinAmountInAppCurrency = computed(() => {
    if (!freespinDepositBonusSettings.value?.freespinBetAmount) return null

    return formatFreespinFiat(
      freespinDepositBonusSettings.value.freespinBetAmount,
    )
  })
  const maxDefaultBonusAmountWithCurrency = computed(() =>
    defaultDepositBonusSettings.value?.maxBonusAmountInBonusCurrency
      ? formatCryptoWithCurrencyCode(
          defaultDepositBonusSettings.value.maxBonusAmountInBonusCurrency,
          bonusesCurrencies.value.default.code,
        )
      : 0,
  )
  const maxDefaultBonusAmount = computed(() =>
    defaultDepositBonusSettings.value?.maxBonusAmountInBonusCurrency
      ? formatCrypto(
          defaultDepositBonusSettings.value.maxBonusAmountInBonusCurrency,
        )
      : 0,
  )
  const maxDepositAmount = computed(() => {
    if (maxDefaultBonusAmount.value) {
      return {
        amount: maxDefaultBonusAmount.value,
        bonusCurrency: bonusesCurrencies.value.default,
      }
    }

    if (maxFreespinBonusFiatAmount.value) {
      return {
        amount: maxFreespinBonusFiatAmount.value,
        bonusCurrency: null,
      }
    }

    return null
  })

  const rollingMultiplier = computed(() => {
    const wager =
      defaultDepositBonusSettings.value?.rollingMultiplier ??
      freespinDepositBonusSettings.value?.rollingMultiplier ??
      freebetDepositBonusSettings.value?.freebetRollingSettings
        .rollingMultiplier

    return wager ? formatMultiplier(wager) : 0
  })

  const minDepositAmount = computed(() => {
    const minFromAmount =
      bonus.value?.minFromAmount ??
      bonus.value?.minDepositAmountInAppCurrency ??
      0
    return formatCurrency(minFromAmount)
  })

  /* 
    все что ниже относится к активным стейтам  (Rolling, canCalm)
    которые будут приходить в bonus/find
    и будет выноситься в другой хук
  */
  const isNewBonus = computed(() => !bonus.value?.bonus)
  const isRolling = computed(
    () =>
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency &&
      new Decimal(
        bonus.value.bonus.currentRollingAmountInBonusCurrency,
      ).lessThan(bonus.value.bonus.fullRollingAmountInBonusCurrency),
  )
  const isCanClaimed = computed(
    () =>
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency &&
      new Decimal(
        bonus.value.bonus.currentRollingAmountInBonusCurrency,
      ).greaterThanOrEqualTo(
        bonus.value.bonus.fullRollingAmountInBonusCurrency,
      ),
  )
  const bonusAmountForClaim = computed(() =>
    formatCrypto(bonus.value?.bonus?.amountInBonusCurrency ?? 0),
  )
  const currentRollingAmount = computed(() =>
    formatCrypto(bonus.value?.bonus?.currentRollingAmountInBonusCurrency ?? 0),
  )
  const fullRollingAmount = computed(() =>
    formatCrypto(bonus.value?.bonus?.fullRollingAmountInBonusCurrency ?? 0),
  )
  const rollingProgress = computed(() => {
    if (
      !bonus.value?.bonus?.needRolling ||
      !bonus.value?.bonus?.currentRollingAmountInBonusCurrency
    )
      return ''

    const result = new Decimal(
      bonus.value.bonus.currentRollingAmountInBonusCurrency,
    )
      .dividedBy(bonus.value.bonus.fullRollingAmountInBonusCurrency)
      .mul(100)
      .toFixed(2)

    return Decimal.min(result, 100).toString()
  })
  const leftSumAmount = computed(() => {
    if (!bonus.value?.bonus) return '0'
    return new Decimal(bonus.value.bonus.fullRollingAmountInBonusCurrency)
      .minus(bonus.value.bonus.currentRollingAmountInBonusCurrency)
      .toString()
  })
  const fullRollingAmountWithCurrency = computed(() =>
    formatCryptoWithCurrencyCode(
      bonus.value?.bonus?.fullRollingAmountInBonusCurrency ?? 0,
      bonusesCurrencies.value.default.code,
    ),
  )
  const currentRollingAmountWithCurrency = computed(() =>
    formatCryptoWithCurrencyCode(
      bonus.value?.bonus?.currentRollingAmountInBonusCurrency ?? 0,
      bonusesCurrencies.value.default.code,
    ),
  )
  const formatedLeftSumAmount = computed(() =>
    formatCryptoWithCurrencyCode(
      leftSumAmount.value,
      bonusesCurrencies.value.default.code,
    ),
  )
  const formatedMinSumAmount = computed(() =>
    formatCryptoWithCurrencyCode(0, bonusesCurrencies.value.default.code),
  )

  const { updateBonusCounters } = useBonusesStore()
  const { fetchDepositBonuses } = useDepositBonusesStore()
  const { execute: claim, status: claimStatus } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value?.bonus?.id ?? 0,
    })),
    immediate: false,
    watch: false,
  })

  async function handleBonusClaim() {
    try {
      await claim()
      await fetchDepositBonuses()
      await updateBonusCounters()
    } catch (err) {
      console.error(err)
    }
  }

  const {
    rollingStatus,
    isRollingStatusInProgress,
    isRollingStatusNewOrPaused,
    setCurrentRolling,
  } = useBonusRolling(computed(() => bonus.value?.bonus?.rolling || null))

  const progressBarColor = computed(() =>
    isRollingStatusNewOrPaused.value ? '#4E4E60' : undefined,
  )

  return {
    isNewBonus,
    isRolling,
    isCanClaimed,
    bonusAmountForClaim,
    currentRollingAmount,
    fullRollingAmount,
    rollingMultiplier,
    rollingProgress,
    claimStatus,
    leftSumAmount,
    fullRollingAmountWithCurrency,
    currentRollingAmountWithCurrency,
    handleBonusClaim,
    maxDepositAmount,
    minDepositAmount,
    isMultiBonus,
    defaultDepositBonusSettings,
    freebetDepositBonusSettings,
    freespinDepositBonusSettings,
    reloadDepositBonusSettings,
    titleType,
    titleByLevel,
    subTitle,
    filteredMultiBonuses,
    bonusesOutput,
    freespinAmountInAppCurrency,
    bonusesCurrencies,
    maxDefaultBonusAmountWithCurrency,
    maxFreespinBonusFiatAmount,
    formatedLeftSumAmount,
    formatedMinSumAmount,
    maxDefaultBonusAmount,
    progressBarColor,
    rollingStatus,
    isRollingStatusNewOrPaused,
    isRollingStatusInProgress,
    setCurrentRolling,
    isStepBonus,
  }
}
